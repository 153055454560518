import React, {useEffect, useMemo} from "react"
import useModal from "../../modal/useModal"
import Modal from "../../modal/Modal"

import "../../../css/forms.css"
import SyllabusRequestForm from "../SyllabusRequestForm"

const useSyllabusModal = ({location}) => {
  const { isModalVisible, setModalVisibility } = useModal({scrollToTop: false})
  const {state} = location

  useEffect(() => {
    if(state?.showSyllabus === true) {
      setModalVisibility(true)
    }
    if(state?.showSyllabus === false) {
      setModalVisibility(false)
    }
  }, [state, setModalVisibility, location])

  const submitOptIn = async (data) => {
    if(window.dataLayer) {
      window.dataLayer.push({
        'event': 'optIn',
        'OptInFor': 'syllabus',
        'Email': data.email
      })
    }
    await fetch('/', {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data)
    })

    window.location.href = "https://content.launchacademy.com/coding-bootcamp-success"
  }
  const modal = useMemo(() => {
    return <Modal size="large" isVisible={isModalVisible} hide={() => {
      setModalVisibility(false)
    }}>
      <h2>Get Your Syllabus</h2>
      <p className="pt-4">Drop us your email and we'll send our course overview over to your inbox.</p>
      <SyllabusRequestForm onSubmit={submitOptIn} />
    </Modal>
  }, [Modal, setModalVisibility, isModalVisible, location, submitOptIn])



  return {
    modal,
    isModalVisible,
    setModalVisibility
  }
}

export default useSyllabusModal
