import { subDays, addDays } from "date-fns"

import oldSpaceImage from "../images/old-space.jpeg"
import floorTrioImage from "../images/trio-on-floor.jpeg"
import combinedPandemicImage from "../images/combined-pandemic.jpeg"
import capStoneImage from "../images/studentProjects/liftmate-screenshot.png"
import teamProjectImage from "../images/studentProjects/articards-screenshot.png"
import Configuration from "../Configuration"

export const cohortSchedule = [
  {
    id: 37,
    cohort: "Boston #37",
    enrollmentEnds: addDays(new Date(2022, 3, 25), 10,).toISOString(),
    ignitionBegins: new Date(2022, 3, 25).toISOString(),
    preflightBegins: subDays(new Date(2022, 3, 25), 21).toISOString(),
    cohortBegins: addDays(new Date(2022, 3, 25), 56).toISOString(),
    cohortEnds: addDays(new Date(2022, 3, 25), 123).toISOString(),
    buttonURL: Configuration.applicationBaseUrl,
    buttonText: "Apply Today"
  },
  {
    id: 38,
    cohort: "Boston #38",
    enrollmentEnds: addDays(new Date(2022, 6, 25), 10).toISOString(),
    ignitionBegins: subDays(new Date(2022, 6, 25), 0).toISOString(),
    preflightBegins: subDays(new Date(2022, 6, 25), 21).toISOString(),
    cohortBegins: addDays(new Date(2022, 6, 25), 56).toISOString(),
    cohortEnds: addDays(new Date(2022, 6, 25), 128).toISOString(),
    buttonURL: Configuration.applicationBaseUrl,
    buttonText: "Apply Today"
  }
];

export const collaborationContent = [
  {
    id: "capstoneProject",
    altText: "Teacher helping two students",
    header: "Capstone Project",
    body: "Create your own app which you will present to companies at the conclusion of the program. Practice your pitch to land your new software engineering job after graduation!",
  },
  {
    id: "teamProject",
    altText: "Two students collaborating on whiteboarding problem",
    header: "Team Project",
    body: "Build a fully functioning app from the ground up within a team environment.  Practice agile methodologies & gain collaborative work experience to reference in job interviews.",
  },
];

export const courseContent = [
  {
    id: 'order',
    altText: "Two students working at whiteboard",
    header: "Learn the right things, in the right order",
    body:
      "You wouldn’t begin building a house by constructing the roof.  Chronologically optimized learning is no different. Starting with time-tested fundamentals adds rocket fuel to your ultimate learning trajectory.",
  },
  {
    id: 'tech',
    altText: "Three women working on laptops",
    header: "Master the technical skills hiring managers want to see",
    body:
      "Hiring managers inform our curriculum enhancements every 90 days. Before each cohort, we update course content to ensure grads enter job interviews with the skills hiring companies want.",
  },
  {
    id: 'instructors',
    altText: "Lecture with instructor teaching students",
    header: "Work one-on-one with your peers and instructors",
    body:
      "Learning collaboratively in small groups with low student-to-teacher ratios enables students to get unstuck quickly, establish momentum, and accelerate their learning.",
  },
  {
    id: 'practice',
    altText: "Student reading digital article",
    header: "Practice intensive, hands-on coding with working apps",
    body:
      "Understanding how technology works requires a learn-by-building approach. Through daily projects, a collaborative team project, and your personal capstone app, you’ll build skills sought by hiring managers.",
  },
  {
    id: 'job',
    altText: "Teacher drawing diagram on whiteboard",
    header: "Nail the job interview and present with confidence",
    body:
      "Obtain one-on-one career coaching focusing on Github, LinkedIn, and your resume. Practice real-world technical interview formats so that you’re ready to impress hiring managers.",
  },
  {
    id: 'employers',
    altText: "Large gathering of people for an event",
    header: "Open doors with a direct connection to employers",
    body:
      "Plug into our Boston network of over 400 companies who have hired graduates onto their software engineering teams since 2013. Connect with our alumni community and establish your personal network.",
  },
];

export const tuitionContent = [
  {
    id: "upfront",
    altText: "Image of lecture",
    header: "Pay upfront",
    body: "Try before you buy. Pay your full tuition in week 2 of the program. Your deposit is fully refundable anytime prior to this date.",
  },
  {
    id: "afterGraduate",
    altText: "Three women collaborating on laptops",
    header: "Pay after you graduate",
    body: "Defer tuition until 3 months after graduation through our loan partner, Ascent. Calculate payments and <a href=\"https://partner.ascentfunding.com/launchacademy/\">learn more</a>. Includes options for a cost of living stipend for as much as  $6,000.",
  },
  {
    id: "topProgram",
    altText: "Two students collaborating on laptops",
    header: "Massachusetts TOP program",
    body: "Eligible students can extend unemployment benefits through graduation. <a href=\"https://www.mass.gov/how-to/apply-for-the-training-opportunities-program\">Learn more</a>.",
  },
];

export const courseColumns = 3;
export const textColumns = 2;
export const tuitionColumns = 3;
