import React from "react";
import { Button } from "@launchacademy/voyager";
import "@launchacademy/voyager/dist/css/theme/buttons/buttons.css";
import useVideoModal from "./hooks/useVideoModal";

const VideoViewButton = ({
  location,
  children,
  text,
  hideText,
  videoId,
  videoProvider,
  size,
  icon,
  secondary,
  placement,
  className = "",
}) => {
  const {
    modal: videoModal,
    staticForm,
    setModalVisibility: setVideoModalVisibility,
  } = useVideoModal({ location, videoId, videoProvider });
  return (
    <>
      <Button
        to="#videoDialog"
        state={{ videoProvider, videoId }}
        icon={icon}
        onClick={(e) => {
          e.preventDefault();
          setVideoModalVisibility(true);
        }}
        secondary={secondary}
        placement={placement}
        className={`${className}`}
        hideText={hideText}
        size={size}
        text={text}
        aria-label="Play Video"
      >
        {children}
      </Button>

      {videoModal}
    </>
  );
};

export default VideoViewButton;
