import React from "react"
import { useForm } from "react-hook-form"


import Configuration from "../../Configuration"


const SyllabusRequestForm = ({ onSubmit, className ="" }) => {
  const { handleSubmit, register, formState: {errors} } = useForm()
  return <form data-netlify="true" name="syllabus" autoComplete="off" noValidate="noValidate" onSubmit={handleSubmit(onSubmit)} className={`form w-full ${className}`} netlify-honeypot="emailAddress">
    <div className="form__control">
      <label className="form__label" htmlFor="email">Email</label>
      <input className={`form__input w-full ${errors.email ? 'form__input_error' : ''}`} type="email" id="email" {...register("email", {required: true, pattern: Configuration.emailRegex })} />
      {errors.email && <p className="form__error">Invalid email</p>}
    </div>

    <input type="submit" value="Send" className="button button_size_sm" />
    <input type="hidden" value="syllabus" {...register("form-name")} />
    <p hidden>
      <label>ignore: <input value="" {...register("emailAddress", {validate: v => v === ""})} /></label>
    </p>
  </form>
}

export default SyllabusRequestForm
